import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk,
  // createAsyncThunk,
} from "@reduxjs/toolkit";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import { camelizeKeys } from "humps";
import { User } from "main/javascripts/types/user";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { fetchAccountParams } from "./accountParamSlice";
import { authEmailChatUnit } from "../features/emailUser/emailUserSlice";
import { PATH_SCOPE } from "../constants/Constants";

const key = "currentUser";
const adapter = createEntityAdapter<User.User>();

const initialState: User.UserEntityState = adapter.getInitialState({
  currentUser: null,
  isLIFFInitialized: false,
  isLIFFLoggedIn: false,
  isFacebookMessengerInitialized: false,
  isFacebookMessengerLoggedIn: false,
  errors: null,
  loading: false,
});

/** Async **/
export const signInLineUser = createAsyncThunk<
  {
    currentUser: User.User;
  },
  {
    accessToken: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/signInLineUser`, async (args, { rejectWithValue }) => {
  try {
    const { accessToken } = args;
    const result = await createWrapper().post(
      `${PATH_SCOPE}/api/line/users/sign_in.json`,
      { access_token: accessToken }
    );
    return { currentUser: result.data.user };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const signInFacebookUser = createAsyncThunk<
  {
    currentUser: User.User;
  },
  {
    param: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/signInFacebookUser`, async (args, { rejectWithValue }) => {
  try {
    const { param } = args;
    const result = await createWrapper().post(
      `${PATH_SCOPE}/api/facebook/users/sign_in.json`,
      param
    );
    return { currentUser: result.data.user };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const currentUserSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setIsLIFFInitialized: (state, action) => {
      state.isLIFFInitialized = action.payload;
    },
    setIsLIFFLoggedIn: (state, action) => {
      state.isLIFFLoggedIn = action.payload;
    },
    setIsFacebookMessengerInitialized: (state, action) => {
      state.isFacebookMessengerInitialized = action.payload;
    },
    setIsFacebookMessengerLoggedIn: (state, action) => {
      state.isFacebookMessengerLoggedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInLineUser.fulfilled, (state, action) => {
        state.currentUser = action.payload.currentUser;
      })
      .addCase(signInFacebookUser.fulfilled, (state, action) => {
        state.currentUser = action.payload.currentUser;
      })
      .addCase(fetchAccountParams.fulfilled, (state, action) => {
        state.currentUser = action.payload.currentUser;
      })
      .addCase(authEmailChatUnit.fulfilled, (state, action) => {
        state.currentUser = action.payload.user;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state) => {
        // state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: { [key]: User.UserEntityState }) => state[key];

export const currentUserSelector = createSelector(
  stateSelector,
  (state) => state.currentUser
);

export const isLIFFLoggedInSelector = createSelector(
  stateSelector,
  (state) => state.isLIFFLoggedIn
);

/** action export **/
export const {
  setIsLIFFInitialized,
  setIsLIFFLoggedIn,
  setIsFacebookMessengerInitialized,
  setIsFacebookMessengerLoggedIn,
} = currentUserSlice.actions;
